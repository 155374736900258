import { createContext, useContext, useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleNotification = (event) => {
      showNotification(event.detail);
    };

    window.addEventListener('showNotification', handleNotification);
    return () => window.removeEventListener('showNotification', handleNotification);
  }, []);

  const showNotification = (notificationData) => {
    setNotification(notificationData);
    // Auto-hide after 3 seconds
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notification && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ 
            vertical: isMobile ? 'top' : 'bottom', 
            horizontal: 'right' 
          }}
          sx={{
            '& .MuiSnackbar-root': {
              minWidth: isMobile ? '100%' : '400px',
            }
          }}
        >
          <Alert
            variant="filled"
            color={notification.type === 'success' ? 'success' : 
                   notification.type === 'error' ? 'error' : 
                   'info'}
            sx={{
              width: '100%',
              backgroundColor: notification.type === 'success' ? '#2e7d32' : 
                             notification.type === 'error' ? '#d32f2f' : 
                             '#0288d1',
              color: '#fff',
              '& .MuiAlert-message': {
                width: '100%'
              },
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div>
              <div style={{ 
                fontWeight: 'bold',
                marginBottom: '4px',
                fontSize: '1rem'
              }}>
                {notification.title}
              </div>
              <div style={{ 
                fontSize: '0.875rem',
                opacity: 0.9 
              }}>
                {notification.message}
              </div>
            </div>
          </Alert>
        </Snackbar>
      )}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};