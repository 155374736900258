import { Card, Typography, Stack, Box, Divider, Grid, LinearProgress } from '@mui/material';
import { useMarket } from '../../../contexts/MarketContext';
import { useGameState } from '../../../contexts/GameStateContext';
import { formatLargeNumber } from '../../../utils/formatters';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { alpha } from '@mui/material/styles';
import { AccountTree } from '@mui/icons-material';
import TimelineIcon from '@mui/icons-material/Timeline';
import PortfolioHoldings from './PortfolioHoldings';

const MetricBox = ({ label, value, subValue, color = 'primary', icon }) => (
  <Box sx={{
    p: 2,
    borderRadius: 2,
    bgcolor: (theme) => `${theme.palette[color].main}15`,
    border: 1,
    borderColor: (theme) => `${theme.palette[color].main}30`,
    height: '100%',
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
      {icon}
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </Box>
    <Typography variant="h5" color={`${color}.main`} sx={{ fontWeight: 600 }}>
      {value}
    </Typography>
    {subValue && (
      <Typography variant="body2" color={`${color}.main`} sx={{ opacity: 0.8 }}>
        {subValue}
      </Typography>
    )}
  </Box>
);

const PortfolioSummary = () => {
  const { state: marketState, dispatch: marketDispatch } = useMarket();
  const { positions } = marketState.portfolio;

  const portfolioStats = Object.entries(positions).reduce((stats, [sym, pos]) => {
    const asset = Object.values(marketState.assets)
      .flat()
      .find(a => a?.symbol === sym);
    
    if (asset) {
      const positionValue = asset.price * pos.shares;
      const previousValue = asset.previousPrice * pos.shares;
      const costBasis = pos.avgPrice * pos.shares;
      
      const dayChange = positionValue - previousValue;
      const dayChangePercent = (dayChange / previousValue) * 100;
      const totalChange = positionValue - costBasis;
      const totalChangePercent = (totalChange / costBasis) * 100;
      
      stats.totalValue += positionValue;
      stats.totalCostBasis += costBasis;
      stats.dayChange += dayChange;
      stats.totalChange += totalChange;
      stats.positions.push({
        symbol: sym,
        shares: pos.shares,
        value: positionValue,
        costBasis,
        dayChange,
        dayChangePercent,
        totalChange,
        totalChangePercent,
        avgPrice: pos.avgPrice,
        weight: 0
      });

      if (!stats.categories.includes(asset.category)) {
        stats.categories.push(asset.category);
      }
    }
    return stats;
  }, { 
    totalValue: 0, 
    totalCostBasis: 0,
    dayChange: 0, 
    totalChange: 0,
    positions: [],
    categories: []
  });

  // Update ATH if current value is higher
  if (portfolioStats.totalValue > (marketState.portfolio.allTimeHigh || 0)) {
    marketDispatch({
      type: 'UPDATE_PORTFOLIO_ATH',
      payload: portfolioStats.totalValue
    });
  }

  const dayChangePercent = portfolioStats.totalValue > 0 
    ? (portfolioStats.dayChange / (portfolioStats.totalValue - portfolioStats.dayChange)) * 100 
    : 0;

  const totalChangePercent = portfolioStats.totalCostBasis > 0 
    ? (portfolioStats.totalChange / portfolioStats.totalCostBasis) * 100 
    : 0;

  // Calculate diversification score
  const availableCategories = [...new Set(
    Object.values(marketState.assets)
      .flat()
      .map(asset => asset.category)
      .filter(Boolean)
  )];
  const totalCategories = availableCategories.length;
  const diversificationScore = totalCategories > 0 
    ? (portfolioStats.categories.length / totalCategories) * 100 
    : 0;

  portfolioStats.largestPosition = portfolioStats.positions.reduce((max, pos) => pos.value > (max?.value || 0) ? pos : max, null);

  portfolioStats.positions = portfolioStats.positions.map(pos => ({
    ...pos,
    weight: portfolioStats.totalValue > 0 ? (pos.value / portfolioStats.totalValue) * 100 : 0
  }));

  return (
    <Card sx={{
      p: 3,
      borderRadius: 2,
      background: (theme) => `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.light}10 100%)`,
    }}>
      <Stack spacing={3}>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>Portfolio Summary</Typography>
        
        <Grid container spacing={2}>
          {/* Additional metrics */}
          <Grid item xs={12} md={4}>
            <MetricBox
              label="Total Return"
              value={`$${formatLargeNumber(portfolioStats.totalChange.toFixed(2))}`}
              subValue={`${totalChangePercent >= 0 ? '+' : ''}${totalChangePercent.toFixed(2)}%`}
              color={totalChangePercent >= 0 ? 'success' : 'error'}
              icon={<TrendingUpIcon />}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <MetricBox
              label="Day Change"
              value={`$${formatLargeNumber(portfolioStats.dayChange)}`}
              subValue={`${dayChangePercent >= 0 ? '+' : ''}${dayChangePercent.toFixed(2)}%`}
              color={portfolioStats.dayChange >= 0 ? 'success' : 'error'}
              icon={portfolioStats.dayChange >= 0 ? <TrendingUpIcon /> : <TrendingDownIcon />}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <MetricBox
              label="Total Investment"
              value={`$${formatLargeNumber(portfolioStats.totalCostBasis)}`}
              subValue={`Cost Basis`}
              color="primary"
              icon={<AccountBalanceIcon />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MetricBox
              label="Total Portfolio Value"
              value={`$${formatLargeNumber(portfolioStats.totalValue)}`}
              subValue={`${dayChangePercent >= 0 ? '+' : ''}${dayChangePercent.toFixed(2)}% today`}
              color={dayChangePercent >= 0 ? 'success' : 'error'}
              icon={<AccountBalanceWalletIcon />}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <MetricBox
              label="All-Time High"
              value={`$${formatLargeNumber(marketState.portfolio.allTimeHigh || 0)}`}
              subValue={portfolioStats.totalValue >= (marketState.portfolio.allTimeHigh || 0) ? 'At Peak' : 'Previous High'}
              color={portfolioStats.totalValue >= (marketState.portfolio.allTimeHigh || 0) ? 'success' : 'info'}
              icon={<TimelineIcon />}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <MetricBox
              label="Biggest Position"
              value={`$${formatLargeNumber(portfolioStats.largestPosition?.value || 0)}`}
              subValue={portfolioStats.largestPosition?.symbol || 'No positions'}
              color="info"
              icon={<AccountBalanceIcon />}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Holdings</Typography>
          <PortfolioHoldings positions={portfolioStats.positions} />
        </Box>
      </Stack>
    </Card>
  );
};

export default PortfolioSummary;
