import { Component } from 'react';
import { Card, Typography, Button, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleReset = () => {
    this.setState({ hasError: false, error: null });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Card
          variant="outlined"
          sx={{
            p: 4,
            m: 2,
            textAlign: 'center',
            maxWidth: 500,
            mx: 'auto',
            borderRadius: 1,
            bgcolor: 'background.paper',
            border: 1,
            borderColor: 'error.main'
          }}
        >
          <Stack spacing={2} alignItems="center">
            <ErrorOutlineIcon 
              sx={{ 
                fontSize: 48,
                color: 'error.main'
              }} 
            />
            <Typography variant="h4">Oops! Something went wrong</Typography>
            <Typography variant="body1">
              Don't worry, your progress is saved. Try refreshing the page.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleReset}
              sx={{ minWidth: 200 }}
            >
              Refresh Game
            </Button>
          </Stack>
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;