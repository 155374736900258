import { Box, Stack, Grid, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useMarket } from '../../contexts/MarketContext';
import MarketCycle from './MarketCycle/CycleIndicator';
import NewsFeed from './MarketNews/NewsFeed';
import AssetTable from './Trading/AssetTable';
import Portfolio from './Portfolio/PortfolioSummary';
import PortfolioChart from './Portfolio/PortfolioChart';
import AssetBreakdown from './Portfolio/AssetBreakdown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MARKET_UNLOCK_CONDITIONS } from '../../data/gameStatePrefab';
import { formatLargeNumber } from '../../utils/formatters';

const MarketContent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { state } = useMarket();
  
  if (!state) {
    return null;
  }

  const isCategoryUnlocked = (category) => {
    if (category === 'stocks') return true;
    return state?.unlockedCategories?.includes(category) || false;
  };

  const getUnlockText = (category) => {
    switch(category) {
      case 'etfs':
        return 'Unlock at $1M cash or 50 employees';
      case 'commodities':
        return 'Unlock at $10M cash or 200 employees';
      case 'crypto':
        return 'Unlock at $50M cash or 500 employees';
      default:
        return '';
    }
  };

  return (
    <Grid 
      container 
      spacing={3} 
      sx={{ 
        p: { xs: 0.5, sm: 2 }  // Reduce padding on mobile
      }}
    >
      {/* Market Overview Section */}
      <Grid item xs={12} md={isMobile ? 12 : 8}>
        <Stack spacing={3} sx={{ 
          // Add negative margin to counteract container padding on mobile
          mx: { xs: -0.5, sm: 0 }
        }}>
          <MarketCycle />
          
          {/* Asset Tables Section */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 3,
            '& > *': {
              boxShadow: (theme) => `0 2px 8px ${theme.palette.primary.main}10`,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
              borderRadius: { xs: 0, sm: 1 }
            }
          }}>
            <Accordion 
              defaultExpanded 
              disabled={!isCategoryUnlocked('stocks')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="stocks-content"
                id="stocks-header"
              >
                Stocks
              </AccordionSummary>
              <AccordionDetails>
                <AssetTable category="stocks" />
              </AccordionDetails>
            </Accordion>

            <Accordion 
              disabled={!isCategoryUnlocked('etfs')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="etfs-content"
                id="etfs-header"
              >
                ETFs {!isCategoryUnlocked('etfs') && 
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                    ({getUnlockText('etfs')})
                  </Typography>
                }
              </AccordionSummary>
              <AccordionDetails>
                <AssetTable category="etfs" />
              </AccordionDetails>
            </Accordion>

            <Accordion 
              disabled={!isCategoryUnlocked('commodities')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="commodities-content"
                id="commodities-header"
              >
                Commodities {!isCategoryUnlocked('commodities') && 
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                    ({getUnlockText('commodities')})
                  </Typography>
                }
              </AccordionSummary>
              <AccordionDetails>
                <AssetTable category="commodities" />
              </AccordionDetails>
            </Accordion>

            <Accordion 
              disabled={!isCategoryUnlocked('crypto')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="crypto-content"
                id="crypto-header"
              >
                Cryptocurrencies {!isCategoryUnlocked('crypto') && 
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                    ({getUnlockText('crypto')})
                  </Typography>
                }
              </AccordionSummary>
              <AccordionDetails>
                <AssetTable category="crypto" />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Stack>
      </Grid>

      {/* Portfolio Section */}
      <Grid item xs={12} md={isMobile ? 12 : 4}>
        <Stack spacing={3} sx={{ 
          // Add negative margin to counteract container padding on mobile
          mx: { xs: -0.5, sm: 0 }
        }}>
          <Box sx={{ 
            '& > *': {
              mb: 3,
              boxShadow: (theme) => `0 2px 8px ${theme.palette.primary.main}10`,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
              borderRadius: { xs: 0, sm: 1 }
            }
          }}>
            <Portfolio />
            <PortfolioChart />
            <AssetBreakdown />
            <NewsFeed />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MarketContent;
