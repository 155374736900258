import { Card, Stack, Typography } from '@mui/material';
import StaffList from './StaffList';
import StaffStats from './StaffStats';
import StaffButton from './StaffButton';
import { useGameState } from '../../../contexts/GameStateContext';
import { GAME_CONSTANTS } from '../../../data/gameConstants';
import { calculateStaffCost } from '../../../services/staffService';

const StaffManager = () => {
  const { state, dispatch } = useGameState();

  const handleHire = (staffType) => {
    const currentCount = state.staff?.[staffType.id]?.count || 0;
    const cost = calculateStaffCost(staffType.id, currentCount);
    
    if (state.money >= cost) {
      dispatch({
        type: 'HIRE_STAFF',
        payload: { 
          staffType: staffType.id,
          cost
        }
      });
    }
  };

  const availableStaffTypes = Object.entries(GAME_CONSTANTS.STAFF)
    .map(([key, config]) => ({
      id: key.toLowerCase(),
      name: config.name,
      baseIncome: config.BASE_INCOME,
      requirement: config.requirement
    }))
    .filter(staffType => {
      if (staffType.id === 'intern') return true;
      if (staffType.requirement?.staff) {
        return Object.entries(staffType.requirement.staff)
          .every(([type, count]) => (state.staff?.[type]?.count || 0) >= count);
      }
      return false;
    });

  if (availableStaffTypes.length === 0) return null;

  return (
    <Card 
      variant="outlined" 
      sx={{ 
        p: 2,
        borderRadius: 1,
        bgcolor: 'background.paper'
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>Hire Staff</Typography>
      <Stack spacing={2}>
        {availableStaffTypes.map(staffType => (
          <StaffButton
            key={staffType.id}
            staffType={staffType}
            onHire={() => handleHire(staffType)}
          />
        ))}
      </Stack>
    </Card>
  );
};

export default StaffManager; 