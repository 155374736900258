// News templates and generation logic
export const NEWS_TEMPLATES = {
  events: {
    ipo: {
      headline: "{symbol} enters the market",
      content: "New company {name} ({symbol}) has launched its Initial Public Offering at ${price}."
    },
    failure: {
      headline: "{symbol} faces major setback",
      content: "Company {name} ({symbol}) has experienced significant losses, leading to a dramatic price drop."
    },
    buyback: {
      headline: "{symbol} announces buyback program",
      content: "{name} ({symbol}) has initiated a stock buyback program, showing confidence in their market position."
    }
  },
  announcements: {
    fed: {
      bullish: [
        {
          headline: "Fed Announces Surprise Rate Cut",
          content: "The Federal Reserve has announced an unexpected interest rate cut, citing economic conditions."
        },
        {
          headline: "Fed Signals More Accommodative Stance",
          content: "Federal Reserve officials indicate a more dovish approach to monetary policy ahead."
        }
      ],
      bearish: [
        {
          headline: "Fed Raises Interest Rates",
          content: "The Federal Reserve has increased interest rates to combat inflation pressures."
        },
        {
          headline: "Fed Warns of Economic Headwinds",
          content: "Federal Reserve officials express concerns about economic growth and inflation."
        }
      ]
    },
    sec: {
      bullish: [
        {
          headline: "SEC Approves New Market Rules",
          content: "Securities and Exchange Commission introduces regulations to enhance market stability."
        }
      ],
      bearish: [
        {
          headline: "SEC Launches Market Investigation",
          content: "Securities and Exchange Commission announces broad investigation into market practices."
        }
      ]
    },
    global: {
      bullish: [
        {
          headline: "China Announces Economic Stimulus",
          content: "Chinese government unveils major economic stimulus package to boost growth."
        },
        {
          headline: "EU Reports Strong Economic Data",
          content: "European Union economic indicators show stronger than expected growth."
        }
      ],
      bearish: [
        {
          headline: "Global Trade Tensions Rise",
          content: "International trade relations deteriorate as new restrictions are announced."
        },
        {
          headline: "Asian Markets Signal Economic Slowdown",
          content: "Key Asian economies report concerning economic indicators."
        }
      ]
    }
  }
};

export const NewsGenerator = {
  generateMarketNews() {
    return [];
  },

  generateEventNews(type, details) {
    const template = NEWS_TEMPLATES.events[type];
    if (!template) return null;
    
    return {
      type,
      headline: template.headline
        .replace('{symbol}', details.symbol)
        .replace('{name}', details.name),
      content: template.content
        .replace('{symbol}', details.symbol)
        .replace('{name}', details.name)
        .replace('{price}', details.price?.toFixed(2) || '0.00'),
      impact: type === 'ipo' ? 'positive' : 
             type === 'failure' ? 'negative' : 
             'neutral',
      timestamp: Date.now()
    };
  },

  generateAnnouncementNews(marketStrength) {
    // Determine if news should be bullish or bearish based on market strength
    const sentiment = marketStrength > 0.5 ? 'bullish' : 'bearish';
    
    // Select random announcement type
    const types = ['fed', 'sec', 'global'];
    const type = types[Math.floor(Math.random() * types.length)];
    
    // Get relevant news templates
    const templates = NEWS_TEMPLATES.announcements[type][sentiment];
    const template = templates[Math.floor(Math.random() * templates.length)];
    
    return {
      type: 'announcement',
      headline: template.headline,
      content: template.content,
      impact: sentiment === 'bullish' ? 'positive' : 'negative',
      timestamp: Date.now()
    };
  }
};
