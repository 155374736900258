// Initial market data and methods for market evolution
import { generatePerlinNoise } from '../utils/calculations';

// Base asset templates for different categories
export const ASSET_TEMPLATES = {
  stocks: [
    // Technology Sector
    { symbol: 'TECH', name: 'TechCorp', basePrice: 100, volatility: 0.03, sector: 'Technology' },
    { symbol: 'DIGI', name: 'DigiTech', basePrice: 110, volatility: 0.033, sector: 'Technology' },
    { symbol: 'SOFT', name: 'SoftCorp', basePrice: 130, volatility: 0.029, sector: 'Technology' },
    { symbol: 'CLOD', name: 'CloudTech', basePrice: 145, volatility: 0.035, sector: 'Technology' },
    { symbol: 'CNET', name: 'CyberNet', basePrice: 95, volatility: 0.032, sector: 'Technology' },
    { symbol: 'DTSC', name: 'DataSci', basePrice: 115, volatility: 0.034, sector: 'Technology' },
    { symbol: 'WEBT', name: 'WebTech', basePrice: 85, volatility: 0.031, sector: 'Technology' },
    
    // Finance Sector
    { symbol: 'FNCE', name: 'FinanceGroup', basePrice: 150, volatility: 0.025, sector: 'Finance' },
    { symbol: 'BNKG', name: 'BankGroup', basePrice: 165, volatility: 0.024, sector: 'Finance' },
    { symbol: 'PYMT', name: 'PaymentTech', basePrice: 140, volatility: 0.028, sector: 'Finance' },
    { symbol: 'INST', name: 'InvestCorp', basePrice: 175, volatility: 0.026, sector: 'Finance' },
    { symbol: 'INSR', name: 'InsureTech', basePrice: 135, volatility: 0.023, sector: 'Finance' },
    { symbol: 'FINT', name: 'FinTech', basePrice: 155, volatility: 0.027, sector: 'Finance' },
    
    // Energy Sector
    { symbol: 'ENRG', name: 'EnergyInc', basePrice: 75, volatility: 0.035, sector: 'Energy' },
    { symbol: 'SOLR', name: 'SolarTech', basePrice: 80, volatility: 0.037, sector: 'Energy' },
    { symbol: 'WIND', name: 'WindPower', basePrice: 70, volatility: 0.036, sector: 'Energy' },
    { symbol: 'NUCL', name: 'NuclearTech', basePrice: 95, volatility: 0.038, sector: 'Energy' },
    { symbol: 'BATT', name: 'BatteryTech', basePrice: 85, volatility: 0.039, sector: 'Energy' },
    
    // Healthcare Sector
    { symbol: 'HLTH', name: 'HealthTech', basePrice: 120, volatility: 0.028, sector: 'Healthcare' },
    { symbol: 'MEDD', name: 'MedDevice', basePrice: 125, volatility: 0.029, sector: 'Healthcare' },
    { symbol: 'BIOT', name: 'BioTech', basePrice: 135, volatility: 0.034, sector: 'Healthcare' },
    { symbol: 'PHRM', name: 'PharmaCorp', basePrice: 145, volatility: 0.027, sector: 'Healthcare' },
    { symbol: 'DIAG', name: 'DiagnosticTech', basePrice: 115, volatility: 0.031, sector: 'Healthcare' },
    
    // Retail Sector
    { symbol: 'RETL', name: 'RetailCo', basePrice: 65, volatility: 0.032, sector: 'Retail' },
    { symbol: 'ECOM', name: 'EcommerceCo', basePrice: 75, volatility: 0.033, sector: 'Retail' },
    { symbol: 'LUXR', name: 'LuxuryRetail', basePrice: 95, volatility: 0.034, sector: 'Retail' },
    { symbol: 'GROC', name: 'GroceryCo', basePrice: 55, volatility: 0.025, sector: 'Retail' },
    
    // Automotive & Transportation
    { symbol: 'AUTO', name: 'AutoTech', basePrice: 85, volatility: 0.04, sector: 'Automotive' },
    { symbol: 'EVTC', name: 'EVTech', basePrice: 95, volatility: 0.045, sector: 'Automotive' },
    { symbol: 'MOBL', name: 'MobilityTech', basePrice: 75, volatility: 0.042, sector: 'Automotive' },
    { symbol: 'LOGI', name: 'LogisticsCo', basePrice: 80, volatility: 0.035, sector: 'Transportation' },
    
    // Aerospace & Defense
    { symbol: 'AERO', name: 'AeroSpace', basePrice: 200, volatility: 0.045, sector: 'Aerospace' },
    { symbol: 'DRON', name: 'DroneTech', basePrice: 150, volatility: 0.047, sector: 'Aerospace' },
    { symbol: 'DFNS', name: 'DefenseTech', basePrice: 180, volatility: 0.043, sector: 'Defense' },
    
    // Semiconductors
    { symbol: 'SEMI', name: 'SemiCorp', basePrice: 180, volatility: 0.038, sector: 'Semiconductors' },
    { symbol: 'CHIP', name: 'ChipTech', basePrice: 190, volatility: 0.039, sector: 'Semiconductors' },
    { symbol: 'PRCS', name: 'ProcessorTech', basePrice: 170, volatility: 0.037, sector: 'Semiconductors' },
    
    // Entertainment & Gaming
    { symbol: 'GAME', name: 'GameTech', basePrice: 95, volatility: 0.044, sector: 'Gaming' },
    { symbol: 'ENTM', name: 'EntertainTech', basePrice: 85, volatility: 0.041, sector: 'Entertainment' },
    { symbol: 'STRM', name: 'StreamTech', basePrice: 90, volatility: 0.043, sector: 'Entertainment' },
    
    // AI & Robotics
    { symbol: 'ROBO', name: 'RoboTech', basePrice: 160, volatility: 0.042, sector: 'Robotics' },
    { symbol: 'AITC', name: 'AITech', basePrice: 175, volatility: 0.044, sector: 'AI' },
    { symbol: 'MACH', name: 'MachineTech', basePrice: 155, volatility: 0.041, sector: 'Robotics' },
    
    // Meta & VR
    { symbol: 'META', name: 'MetaTech', basePrice: 140, volatility: 0.041, sector: 'Metaverse' },
    { symbol: 'VRTC', name: 'VRTech', basePrice: 130, volatility: 0.043, sector: 'Metaverse' },
    { symbol: 'AUGM', name: 'AugmentTech', basePrice: 125, volatility: 0.042, sector: 'Metaverse' }
  ],
  
  etfs: [
    // Broad Market
    { symbol: 'INDX', name: 'Market Index', basePrice: 200, volatility: 0.02, sector: 'Index' },
    { symbol: 'SP5H', name: 'SP500 High', basePrice: 220, volatility: 0.022, sector: 'Index' },
    { symbol: 'GLBL', name: 'Global Markets', basePrice: 185, volatility: 0.024, sector: 'Index' },
    { symbol: 'EMKT', name: 'Emerging Markets', basePrice: 165, volatility: 0.028, sector: 'Index' },
    
    // Sector ETFs
    { symbol: 'TECH', name: 'Tech Sector', basePrice: 180, volatility: 0.025, sector: 'Technology' },
    { symbol: 'FNCE', name: 'Finance Sector', basePrice: 170, volatility: 0.022, sector: 'Finance' },
    { symbol: 'HLTH', name: 'Healthcare Sector', basePrice: 160, volatility: 0.023, sector: 'Healthcare' },
    { symbol: 'ENRG', name: 'Energy Sector', basePrice: 150, volatility: 0.028, sector: 'Energy' },
    { symbol: 'CYBR', name: 'Cybersecurity', basePrice: 175, volatility: 0.026, sector: 'Technology' },
    { symbol: 'ROBT', name: 'Robotics & AI', basePrice: 165, volatility: 0.027, sector: 'Technology' },
    
    // Strategy ETFs
    { symbol: 'GROW', name: 'Growth Fund', basePrice: 190, volatility: 0.03, sector: 'Growth' },
    { symbol: 'DIVD', name: 'Dividend Fund', basePrice: 165, volatility: 0.018, sector: 'Dividend' },
    { symbol: 'VALU', name: 'Value Fund', basePrice: 155, volatility: 0.019, sector: 'Value' },
    { symbol: 'SMCP', name: 'Small Cap Fund', basePrice: 145, volatility: 0.029, sector: 'Small Cap' },
    { symbol: 'LGCP', name: 'Large Cap Fund', basePrice: 185, volatility: 0.021, sector: 'Large Cap' },
    
    // Bond ETFs
    { symbol: 'BOND', name: 'Bond Fund', basePrice: 145, volatility: 0.015, sector: 'Bonds' },
    { symbol: 'GOVT', name: 'Government Bonds', basePrice: 140, volatility: 0.012, sector: 'Bonds' },
    { symbol: 'CORP', name: 'Corporate Bonds', basePrice: 150, volatility: 0.016, sector: 'Bonds' },
    { symbol: 'HYLD', name: 'High Yield Bonds', basePrice: 135, volatility: 0.02, sector: 'Bonds' }
  ],
  
  commodities: [
    // Precious Metals
    { symbol: 'GOLD', name: 'Gold', basePrice: 1800, volatility: 0.015, sector: 'Precious Metals' },
    { symbol: 'SILV', name: 'Silver', basePrice: 25, volatility: 0.02, sector: 'Precious Metals' },
    { symbol: 'PLAT', name: 'Platinum', basePrice: 1000, volatility: 0.018, sector: 'Precious Metals' },
    { symbol: 'PLDM', name: 'Palladium', basePrice: 2200, volatility: 0.022, sector: 'Precious Metals' },
    
    // Industrial Metals
    { symbol: 'COPR', name: 'Copper', basePrice: 4, volatility: 0.025, sector: 'Industrial Metals' },
    { symbol: 'IRON', name: 'Iron Ore', basePrice: 120, volatility: 0.028, sector: 'Industrial Metals' },
    { symbol: 'ALUM', name: 'Aluminum', basePrice: 2.5, volatility: 0.026, sector: 'Industrial Metals' },
    { symbol: 'NICK', name: 'Nickel', basePrice: 8, volatility: 0.027, sector: 'Industrial Metals' },
    { symbol: 'ZINC', name: 'Zinc', basePrice: 3, volatility: 0.024, sector: 'Industrial Metals' },
    
    // Energy
    { symbol: 'OIL', name: 'Crude Oil', basePrice: 75, volatility: 0.035, sector: 'Energy' },
    { symbol: 'NGAS', name: 'Natural Gas', basePrice: 3, volatility: 0.04, sector: 'Energy' },
    { symbol: 'COAL', name: 'Coal', basePrice: 150, volatility: 0.032, sector: 'Energy' },
    { symbol: 'URAN', name: 'Uranium', basePrice: 45, volatility: 0.038, sector: 'Energy' },
    
    // Agriculture
    { symbol: 'WHET', name: 'Wheat', basePrice: 7, volatility: 0.028, sector: 'Agriculture' },
    { symbol: 'CORN', name: 'Corn', basePrice: 6, volatility: 0.027, sector: 'Agriculture' },
    { symbol: 'SOYB', name: 'Soybeans', basePrice: 14, volatility: 0.026, sector: 'Agriculture' },
    { symbol: 'RICE', name: 'Rice', basePrice: 13, volatility: 0.025, sector: 'Agriculture' },
    { symbol: 'COFE', name: 'Coffee', basePrice: 180, volatility: 0.03, sector: 'Agriculture' },
    { symbol: 'COTN', name: 'Cotton', basePrice: 85, volatility: 0.029, sector: 'Agriculture' }
  ],
  
  crypto: [
    // Large Cap Crypto
    { symbol: 'BTC', name: 'Bitcoin', basePrice: 45000, volatility: 0.05, sector: 'Cryptocurrency' },
    { symbol: 'ETH', name: 'Ethereum', basePrice: 3000, volatility: 0.055, sector: 'Cryptocurrency' },
    { symbol: 'BNB', name: 'Binance Coin', basePrice: 400, volatility: 0.058, sector: 'Cryptocurrency' },
    
    // Smart Contract Platforms
    { symbol: 'SOL', name: 'Solana', basePrice: 100, volatility: 0.07, sector: 'Cryptocurrency' },
    { symbol: 'DOT', name: 'Polkadot', basePrice: 25, volatility: 0.065, sector: 'Cryptocurrency' },
    { symbol: 'ADA', name: 'Cardano', basePrice: 2, volatility: 0.06, sector: 'Cryptocurrency' },
    { symbol: 'AVAX', name: 'Avalanche', basePrice: 80, volatility: 0.068, sector: 'Cryptocurrency' },
    
    // DeFi Tokens
    { symbol: 'LINK', name: 'Chainlink', basePrice: 15, volatility: 0.062, sector: 'Cryptocurrency' },
    { symbol: 'UNI', name: 'Uniswap', basePrice: 20, volatility: 0.064, sector: 'Cryptocurrency' },
    { symbol: 'AAVE', name: 'Aave', basePrice: 300, volatility: 0.066, sector: 'Cryptocurrency' },
    { symbol: 'MKR', name: 'Maker', basePrice: 2500, volatility: 0.063, sector: 'Cryptocurrency' },
    
    // Gaming & Metaverse
    { symbol: 'MANA', name: 'Decentraland', basePrice: 3, volatility: 0.072, sector: 'Cryptocurrency' },
    { symbol: 'SAND', name: 'The Sandbox', basePrice: 4, volatility: 0.071, sector: 'Cryptocurrency' },
    { symbol: 'AXS', name: 'Axie Infinity', basePrice: 60, volatility: 0.075, sector: 'Cryptocurrency' },
    
    // Layer 2 Solutions
    { symbol: 'MATIC', name: 'Polygon', basePrice: 1.5, volatility: 0.067, sector: 'Cryptocurrency' },
    { symbol: 'LRC', name: 'Loopring', basePrice: 0.5, volatility: 0.069, sector: 'Cryptocurrency' },
    { symbol: 'IMX', name: 'Immutable X', basePrice: 2, volatility: 0.073, sector: 'Cryptocurrency' }
  ]
};

// Market manipulation methods
export const MarketManager = {
  // Initialize market with base assets
  getInitialMarketData() {
    const marketData = {
      stocks: [],
      etfs: [],
      commodities: [],
      crypto: []
    };

    // Define initial counts for non-commodity assets
    const initialCounts = {
      stocks: 5,
      etfs: 3,
      crypto: 2
    };

    // Handle non-commodity assets
    Object.entries(initialCounts).forEach(([category, count]) => {
      const availableAssets = [...ASSET_TEMPLATES[category]];
      
      for (let i = 0; i < count; i++) {
        if (availableAssets.length === 0) break;
        
        const randomIndex = Math.floor(Math.random() * availableAssets.length);
        const selectedAsset = availableAssets.splice(randomIndex, 1)[0];
        
        const priceVariation = 1 + (Math.random() * 0.2 - 0.1);
        const initialPrice = selectedAsset.basePrice * priceVariation;
        
        marketData[category].push({
          ...selectedAsset,
          price: initialPrice,
          previousPrice: initialPrice,
          priceHistory: [initialPrice],
          marketCap: initialPrice * (1000000 + Math.random() * 9000000)
        });
      }
    });

    // Add ALL commodities with initial price variations
    marketData.commodities = ASSET_TEMPLATES.commodities.map(commodity => {
      const priceVariation = 1 + (Math.random() * 0.2 - 0.1);
      const initialPrice = commodity.basePrice * priceVariation;
      
      return {
        ...commodity,
        price: initialPrice,
        previousPrice: initialPrice,
        priceHistory: [initialPrice],
        marketCap: initialPrice * (1000000 + Math.random() * 9000000)
      };
    });

    return marketData;
  },

  getRemainingAssets(currentAssets) {
    const remainingAssets = { ...ASSET_TEMPLATES };
    
    // Only filter non-commodity assets
    ['stocks', 'etfs', 'crypto'].forEach(category => {
      remainingAssets[category] = remainingAssets[category].filter(template => 
        !currentAssets[category].some(active => active.symbol === template.symbol)
      );
    });
    
    // Remove commodities from remaining assets since they're all always available
    delete remainingAssets.commodities;
    
    return remainingAssets;
  },

  // Helper method to get a random asset for IPOs or other events
  getRandomNewAsset(category, currentAssets) {
    // Don't allow new commodity assets
    if (category === 'commodities') return null;

    const availableAssets = ASSET_TEMPLATES[category].filter(template => 
      !currentAssets[category].some(active => active.symbol === template.symbol)
    );

    if (availableAssets.length === 0) return null;
    
    const randomIndex = Math.floor(Math.random() * availableAssets.length);
    const template = availableAssets[randomIndex];
    
    const priceVariation = 1 + (Math.random() * 0.1 - 0.05);
    const initialPrice = template.basePrice * priceVariation;
    
    return {
      ...template,
      price: initialPrice,
      previousPrice: initialPrice,
      priceHistory: [initialPrice],
      marketCap: initialPrice * (1000000 + Math.random() * 9000000)
    };
  },

  // Handle asset failure
  handleAssetFailure(asset) {
    return {
      ...asset,
      price: asset.price * 0.1, // 90% loss
      previousPrice: asset.price,
      status: 'failed'
    };
  },

  // Handle asset acquisition
  handleAssetAcquisition(asset) {
    return {
      ...asset,
      price: asset.price * 1.2, // 20% premium
      previousPrice: asset.price,
      status: 'acquired'
    };
  },

  // Calculate market metrics for a category
  calculateMarketMetrics(assets) {
    if (!assets || assets.length === 0) return null;
    
    return {
      totalMarketCap: assets.reduce((sum, asset) => sum + asset.price, 0),
      averagePrice: assets.reduce((sum, asset) => sum + asset.price, 0) / assets.length,
      volatility: Math.max(...assets.map(asset => asset.volatility))
    };
  },

  // Remove or modify the news generation methods to only handle specific events
  handleMarketNews(marketState, event) {
    // Only handle specific events (IPO, Failure, Buyback)
    if (!event || !event.type) return marketState;

    const newsItem = {
      id: Date.now(),
      timestamp: Date.now(),
      type: event.type,
      headline: event.headline,
      content: event.content,
      impact: event.impact || 'neutral'
    };

    return {
      ...marketState,
      news: [newsItem, ...(marketState.news || [])].slice(0, 10) // Keep last 10 news
    };
  }
};
export default MarketManager;

