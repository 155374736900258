import { GAME_CONSTANTS } from './gameConstants';

export const STAFF_TYPES = Object.keys(GAME_CONSTANTS.STAFF);

export const StaffManager = {
  calculateStaffCost(type, currentCount) {
    const staffConfig = GAME_CONSTANTS.STAFF[type.toUpperCase()];
    return Math.floor(staffConfig.BASE_COST * Math.pow(staffConfig.COST_MULTIPLIER, currentCount));
  },

  calculateStaffIncome(type, count, multiplier = 1) {
    const staffConfig = GAME_CONSTANTS.STAFF[type.toUpperCase()];
    return staffConfig.BASE_INCOME * count * Math.pow(staffConfig.INCOME_MULTIPLIER, count - 1) * multiplier;
  },

  checkStaffRequirement(state, staffType) {
    const requirement = GAME_CONSTANTS.STAFF[staffType.toUpperCase()]?.requirement;
    if (!requirement) return true;

    if (requirement.staff) {
      return Object.entries(requirement.staff).every(([type, count]) => 
        (state.staff[type]?.count || 0) >= count
      );
    }
    return true;
  },

  getAvailableStaffTypes(state) {
    return STAFF_TYPES.filter(type => 
      this.checkStaffRequirement(state, type)
    );
  },

  getTotalIncome(state) {
    return Object.entries(state.staff).reduce((total, [type, data]) => {
      return total + this.calculateStaffIncome(type, data.count, data.multiplier);
    }, 0);
  }
};
