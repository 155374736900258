import { createTheme } from '@mui/material/styles';

// Modern color palette
const colors = {
  primary: {
    main: '#6366F1', // Modern indigo
    light: '#818CF8',
    dark: '#4F46E5',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#EC4899', // Modern pink
    light: '#F472B6',
    dark: '#DB2777',
    contrastText: '#ffffff',
  },
  success: {
    main: '#10B981', // Modern green
    light: '#34D399',
    dark: '#059669',
    contrastText: '#ffffff',
  },
  error: {
    main: '#EF4444', // Modern red
    light: '#F87171',
    dark: '#DC2626',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#F59E0B', // Modern amber
    light: '#FBBF24',
    dark: '#D97706',
    contrastText: '#ffffff',
  },
  info: {
    main: '#3B82F6', // Modern blue
    light: '#60A5FA',
    dark: '#2563EB',
    contrastText: '#ffffff',
  },
};

export const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
    ...colors,
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF',
      dark: {
        default: '#111827',
        paper: '#1F2937',
      },
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563',
      dark: {
        primary: '#F9FAFB',
        secondary: '#D1D5DB',
      },
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 500,
          padding: '8px 16px',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          },
          '&:active': {
            transform: 'translateY(0)',
          },
          '&.training-button': {
            background: `linear-gradient(135deg, ${colors.primary.main}, ${colors.primary.dark})`,
            position: 'relative',
            overflow: 'hidden',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
              opacity: 0,
              transition: 'opacity 0.2s ease-in-out',
            },
            '&:hover:before': {
              opacity: 1,
            },
            '&:disabled': {
              background: `linear-gradient(135deg, ${colors.secondary.main}, ${colors.secondary.dark})`,
              opacity: 0.7,
            },
          },
        },
        contained: {
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          '&:hover': {
            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
          },
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
          },
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 8,
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
        bar: {
          borderRadius: 8,
          backgroundImage: `linear-gradient(90deg, ${colors.success.main}, ${colors.success.light})`,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0 2px 4px rgba(0,0,0,0.05)',
    '0 4px 8px rgba(0,0,0,0.1)',
    '0 8px 16px rgba(0,0,0,0.1)',
    '0 12px 24px rgba(0,0,0,0.1)',
    // ... rest of the shadows
  ],
});

// Create dark theme variant
export const darkTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    mode: 'dark',
    background: {
      default: '#111827',
      paper: '#1F2937',
    },
    text: {
      primary: '#F9FAFB',
      secondary: '#D1D5DB',
    },
  },
});
