const SI_PREFIXES = [
    { value: 1e24, symbol: 'Y' },
    { value: 1e21, symbol: 'Z' },
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' }
  ];
  
  export const formatLargeNumber = (num) => {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    
    if (absNum >= 1000000000) {
      return `${isNegative ? '-' : ''}${(absNum / 1000000000).toFixed(2)}B`;
    }
    if (absNum >= 1000000) {
      return `${isNegative ? '-' : ''}${(absNum / 1000000).toFixed(2)}M`;
    }
    if (absNum >= 1000) {
      return `${isNegative ? '-' : ''}${(absNum / 1000).toFixed(2)}K`;
    }
    return `${isNegative ? '-' : ''}${absNum.toFixed(2)}`;
  };
  
  export const formatPercentage = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }).format(value / 100);
  };
  
  export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };
  
  export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  export const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };