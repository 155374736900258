import { GAME_CONSTANTS } from './gameConstants';
import { MarketManager, ASSET_TEMPLATES } from './marketDataPrefab';
import { StaffManager } from './staffPrefab';

export const MARKET_UNLOCK_CONDITIONS = {
  stocks: 0, // Available from start
  etfs: 500000, // Requires $500k ATH
  commodities: 10000000, // Requires $10M ATH
  crypto: 25000000, // Requires $25M ATH
};

export const initialGameState = {
  money: 0,
  totalEarnings: 0,     // Tracks all money earned (never decreases)
  moneyATH: 0,         // Initialize at 0, it will update to the first money amount
  clickValue: 1,
  clickMultiplier: 1,
  incomePerSecond: 0,
  trainingLevel: 1,
  trainingMultiplier: 1,
  staff: {
    intern: { count: 0, multiplier: 1 },
    worker: { count: 0, multiplier: 1 },
    manager: { count: 0, multiplier: 1 },
    engineer: { count: 0, multiplier: 1 },
    ceo: { count: 0, multiplier: 1 }
  },
  upgrades: [],
  marketUnlocked: false,
  marketState: {
    news: [],
    portfolio: {
      cash: 0,
      positions: {},
      history: []
    },
    marketCycle: {
      phase: 'bull',
      strength: 0.5,
      history: []
    },
    unlockedCategories: ['stocks'],
    assets: {
      stocks: ASSET_TEMPLATES.stocks,
      etfs: ASSET_TEMPLATES.etfs,
      commodities: ASSET_TEMPLATES.commodities,
      crypto: ASSET_TEMPLATES.crypto
    }
  },
  achievements: {
    money1k: false,
    money10k: false,
    money100k: false,
    money1m: false,
    money10m: false,
    money100m: false,
    money1b: false,
    money750k: false,
    employees10: false,
    employees25: false,
    employees50: false,
    employees100: false,
    employees200: false,
    employees500: false,
    employees1000: false,
  }
};