import React from 'react';
import { Button, Typography, Stack, Tooltip } from '@mui/material';
import { useGameState } from '../../../contexts/GameStateContext';
import { calculateStaffCost, calculateStaffIncome } from '../../../services/staffService';
import { formatCurrency, formatLargeNumber } from '../../../utils/formatters';

const StaffButton = ({ staffType, onHire }) => {
  const { state } = useGameState();
  const currentCount = state.staff?.[staffType.id]?.count || 0;
  const cost = calculateStaffCost(staffType.id, currentCount);
  const isAffordable = state.money >= cost;
  
  // Calculate next staff member's income
  const currentIncome = calculateStaffIncome(
    staffType.id, 
    currentCount, 
    state.staff?.[staffType.id]?.multiplier || 1
  );
  
  const nextIncome = calculateStaffIncome(
    staffType.id, 
    currentCount + 1, 
    state.staff?.[staffType.id]?.multiplier || 1
  );
  
  const incomeIncrease = nextIncome - currentIncome;
  
  // Calculate ROI (Return on Investment)
  const secondsToROI = cost / incomeIncrease;
  const roiText = secondsToROI < 60 
    ? `${secondsToROI.toFixed(1)}s` 
    : `${(secondsToROI / 60).toFixed(1)}m`;
  
  return (
    <Button
      variant="contained"
      color={isAffordable ? 'primary' : 'secondary'}
      onClick={onHire}
      disabled={!isAffordable}
      sx={{ 
        p: 2, 
        width: '100%',
        minHeight: '80px',
        borderRadius: 2,
        background: (theme) => isAffordable 
          ? `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
          : undefined,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: isAffordable ? 'translateY(-2px)' : 'none',
          boxShadow: (theme) => isAffordable 
            ? `0 8px 24px ${theme.palette.primary.main}40`
            : undefined,
        },
        '&:active': {
          transform: 'translateY(1px)',
        },
      }}
    >
      <Stack spacing={1} alignItems="center" width="100%">
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'white' }}>
          {staffType.name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'white', opacity: 0.9 }}>
          Count: {currentCount}
        </Typography>
        <Typography variant="body2" sx={{ color: 'white', opacity: 0.9 }}>
          Cost: {formatCurrency(cost)}
        </Typography>
        <Typography variant="body2" sx={{ color: 'white', opacity: 0.9 }}>
          +{formatCurrency(incomeIncrease)}/s
        </Typography>
        <Tooltip title="Time to recover investment">
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'white', 
              opacity: 0.9,
              bgcolor: 'rgba(255,255,255,0.1)',
              px: 1,
              py: 0.5,
              borderRadius: 1
            }}
          >
            ROI: {roiText}
          </Typography>
        </Tooltip>
      </Stack>
    </Button>
  );
};

export default StaffButton;
