import { Button, Typography, Box } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { formatLargeNumber } from '../../utils/formatters';
import WorkIcon from '@mui/icons-material/Work';

const WorkButton = () => {
  const { state, dispatch } = useGameState();

  const handleWork = () => {
    dispatch({ type: 'WORK_CLICK' });
  };

  return (
    <Button
      variant="contained"
      onClick={handleWork}
      sx={{
        width: '100%',
        minHeight: '120px',
        p: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: (theme) => `0 12px 24px ${theme.palette.primary.main}80`,
        },
        '&:active': {
          transform: 'translateY(1px)',
        },
        userSelect: 'none',
        touchAction: 'manipulation',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        mb: 1 
      }}>
        <WorkIcon sx={{ fontSize: 36, color: 'white' }} />
        <Typography variant="h4" sx={{ fontWeight: 700, color: 'white' }}>
          Work
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ opacity: 0.9, color: 'white' }}>
        +${formatLargeNumber(state.clickValue)} / click
      </Typography>
    </Button>
  );
};

export default WorkButton;