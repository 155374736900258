import { Box, Typography, Stack, Divider } from '@mui/material';
import { formatLargeNumber } from '../../../utils/formatters';
import { useMarket } from '../../../contexts/MarketContext';
import TradeModal from '../Trading/TradeModal';
import { useState } from 'react';

const PortfolioHoldings = ({ positions }) => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const { state } = useMarket();

  const handleRowClick = (symbol) => {
    // Find the asset details from any category
    const asset = Object.values(state.assets)
      .flat()
      .find(a => a.symbol === symbol);
    
    if (asset) {
      setSelectedPosition(asset);
    }
  };

  if (!positions || positions.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        No positions yet
      </Typography>
    );
  }

  return (
    <>
      <Stack spacing={1} divider={<Divider />}>
        {positions.map((position) => (
          <Box
            key={position.symbol}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 1,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
            onClick={() => handleRowClick(position.symbol)}
          >
            <Box>
              <Typography variant="body1" fontWeight={500}>
                {position.symbol}
                <Typography 
                  component="span" 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ ml: 1 }}
                >
                  ({position.weight.toFixed(2)}%)
                </Typography>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatLargeNumber(position.shares)} shares @ ${formatLargeNumber(position.avgPrice)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body1">
                ${formatLargeNumber(position.value)}
              </Typography>
              <Typography
                variant="body2"
                color={position.totalChangePercent >= 0 ? 'success.main' : 'error.main'}
              >
                {position.totalChangePercent >= 0 ? '+' : ''}
                {position.totalChangePercent.toFixed(2)}%
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>

      {selectedPosition && (
        <TradeModal
          asset={selectedPosition}
          onClose={() => setSelectedPosition(null)}
        />
      )}
    </>
  );
};

export default PortfolioHoldings; 