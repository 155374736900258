import { Button, Stack, Typography, LinearProgress, Box } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { formatLargeNumber } from '../../utils/formatters';
import { calculateTrainingCost, calculateNextImprovement, calculateTrainingProgress } from '../../services/gameService';
import { formatTime } from '../../utils/formatters';

const TrainingButton = () => {
  const { state, dispatch } = useGameState();
  const cost = calculateTrainingCost(state.trainingLevel);
  const canAfford = state.money >= cost;
  const improvement = calculateNextImprovement(state.trainingLevel, state.clickMultiplier);
  const progress = calculateTrainingProgress(state);

  const handleTrain = () => {
    if (canAfford) {
      dispatch({ type: 'TRAIN' });
    }
  };

  return (
    <Button
      variant="contained"
      color={canAfford ? "primary" : "secondary"}
      onClick={handleTrain}
      disabled={!canAfford}
      className="training-button"
      sx={{ 
        width: '100%',
        minHeight: { xs: '120px', sm: 'auto' },
        p: { xs: 3, sm: 2 },
        transition: 'all 0.3s ease',
        '&:not(:disabled):hover': {
          transform: 'scale(1.02)',
          boxShadow: (theme) => `0 8px 24px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.4)' : 'rgba(99,102,241,0.25)'}`,
        },
        '&:active': {
          transform: { xs: 'scale(0.98)', sm: 'none' }
        }
      }}
    >
      <Stack 
        spacing={{ xs: 2, sm: 1 }}
        alignItems="center" 
        width="100%"
      >
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#fff',
            textShadow: '0 2px 4px rgba(0,0,0,0.2)'
          }}
        >
          Training Level {state.trainingLevel}
        </Typography>
        <Stack 
          direction={{ xs: 'row', sm: 'column' }}
          spacing={2}
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#fff',
              opacity: canAfford ? 1 : 0.7,
              fontWeight: 500
            }}
          >
            Cost: ${formatLargeNumber(cost)}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#fff',
              fontWeight: 500
            }}
          >
            Current: ${formatLargeNumber(state.clickValue)} / click
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#fff',
              fontWeight: 500,
              textShadow: '0 1px 2px rgba(0,0,0,0.2)'
            }}
          >
            Next: +${formatLargeNumber(improvement)} / click
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
};

export default TrainingButton;