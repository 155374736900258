import { GAME_CONSTANTS } from '../data/gameConstants';
import { createNoise2D } from 'simplex-noise';
import { MarketManager } from '../data/marketDataPrefab';
import { NewsGenerator } from '../data/newsPrefab';
import { useNotification } from '../contexts/NotificationContext';

const noise2D = createNoise2D();
let noiseOffset = 0;

export const updateMarketPrices = (assets, marketCycle) => {
  const { phase, strength } = marketCycle;
  
  return assets.map(asset => {
    const volatility = asset.volatility * GAME_CONSTANTS.MARKET.CYCLE.BASE_VOLATILITY;
    
    // Calculate smoother trend based on strength
    const trendStrength = (strength - 0.5) * 2; // -1 to 1 range
    const trend = Math.sign(trendStrength) * Math.pow(Math.abs(trendStrength), 1.5); // Non-linear scaling
    
    // Reduce noise impact and make it proportional to trend strength
    const noise = (Math.random() - 0.5) * 
                 GAME_CONSTANTS.MARKET.CYCLE.NOISE_WEIGHT * 
                 (1 - Math.abs(trendStrength)); // Less noise during strong trends
    
    const priceChange = (
      trend * volatility * 0.3 + // Reduced trend impact
      noise * volatility * 0.7   // Increased noise impact for more natural movement
    );
    
    const newPrice = asset.price * (1 + priceChange);
    
    return {
      ...asset,
      previousPrice: asset.price,
      price: Math.max(newPrice, asset.price * 0.1)
    };
  });
};

export const updateMarketCycle = (currentCycle) => {
  noiseOffset += 0.002;
  const noiseValue = (noise2D(noiseOffset, 0) + 1) / 2;
  
  const biasedValue = noiseValue * (1 - GAME_CONSTANTS.MARKET.CYCLE.BULL_BIAS) + 
                      GAME_CONSTANTS.MARKET.CYCLE.BULL_BIAS;
  
  const smoothedValue = (biasedValue + (currentCycle?.sentiment || biasedValue)) / 2;
  
  return {
    phase: smoothedValue > 0.5 ? 'bull' : 'bear',
    strength: Math.abs(smoothedValue - 0.5) * 2,
    sentiment: smoothedValue
  };
};

export const handleMarketEvent = (state) => {
  const eventRoll = Math.random();
  
  // Event chances per update (keeping high test values)
  const IPO_CHANCE = 0.4;      // 40% chance per update
  const BUYBACK_CHANCE = 0.15; // 15% chance per update
  const FAILURE_CHANCE = 0.1;  // 10% chance per update
  
  if (!state.assets) {
    console.warn('Missing assets in state');
    return state;
  }

  if (eventRoll < IPO_CHANCE) {
    return handleIPO(state);
  } else if (eventRoll < (IPO_CHANCE + BUYBACK_CHANCE)) {
    // Get all non-commodity assets
    const assets = ['stocks', 'etfs', 'crypto']
      .map(category => state.assets[category])
      .flat();
    
    if (assets.length > 0) {
      const randomAsset = assets[Math.floor(Math.random() * assets.length)];
      if (randomAsset && randomAsset.category) {
        return handleBuyback(state, randomAsset);
      }
    }
  } else if (eventRoll < (IPO_CHANCE + BUYBACK_CHANCE + FAILURE_CHANCE)) {
    // Get all non-commodity assets
    const assets = ['stocks', 'etfs', 'crypto']
      .map(category => state.assets[category])
      .flat();
    
    if (assets.length > 0) {
      const randomAsset = assets[Math.floor(Math.random() * assets.length)];
      if (randomAsset && randomAsset.category) {
        return handleFailure(state, randomAsset);
      }
    }
  }
  
  return state;
};

export const unlockMarket = (state) => {
  if (state.money < GAME_CONSTANTS.MARKET_UNLOCK_COST) {
    return state;
  }
  
  return {
    ...state,
    money: state.money - GAME_CONSTANTS.MARKET_UNLOCK_COST,
    marketUnlocked: true
  };
};

export const updateMarket = (state) => {
  if (!state.marketUnlocked) return state;
  
  const newCycle = updateMarketCycle(state.marketCycle);
  const updatedState = { ...state, marketCycle: newCycle };
  
  // Update prices for each category
  Object.keys(state.marketData).forEach(category => {
    updatedState.marketData[category] = updateMarketPrices(
      state.marketData[category],
      newCycle
    );
  });
  
  // Generate news and handle special events
  const newsItems = generateMarketNews(updatedState);
  updatedState.marketNews = [...(state.marketNews || []), ...newsItems]
    .filter(item => item.expiresAt > Date.now())
    .slice(-5); // Keep only last 5 news items
  
  return handleMarketEvent(updatedState);
};

export const handleBuyback = (state, asset) => {
  // Safety check for asset and category
  if (!asset || !asset.category || !state.assets || !state.assets[asset.category]) {
    console.warn('Invalid asset or missing category:', asset);
    return state;
  }

  const position = state.portfolio?.positions?.[asset.symbol];
  const BUYBACK_PREMIUM = 1.10;
  
  let updatedState = state;
  if (position) {
    const buybackValue = position.shares * asset.price * BUYBACK_PREMIUM;
    const newPositions = { ...state.portfolio.positions };
    delete newPositions[asset.symbol];
    
    updatedState = {
      ...state,
      portfolio: {
        ...state.portfolio,
        positions: newPositions,
        cash: state.portfolio.cash + buybackValue
      }
    };
  }

  return {
    ...updatedState,
    assets: {
      ...updatedState.assets,
      [asset.category]: updatedState.assets[asset.category].filter(a => a.symbol !== asset.symbol)
    },
    pendingNotification: {
      type: 'success',
      title: 'Company Buyback',
      message: `${asset.name} (${asset.symbol}) has been bought back with a 10% premium`
    }
  };
};

export const handleFailure = (state, asset) => {
  // Safety check for asset and category
  if (!asset || !asset.category || !state.assets || !state.assets[asset.category]) {
    console.warn('Invalid asset or missing category:', asset);
    return state;
  }

  const position = state.portfolio?.positions?.[asset.symbol];
  const FAILURE_RECOVERY = 0.10;
  
  let updatedState = state;
  if (position) {
    const recoveryValue = position.shares * asset.price * FAILURE_RECOVERY;
    const newPositions = { ...state.portfolio.positions };
    delete newPositions[asset.symbol];
    
    updatedState = {
      ...state,
      portfolio: {
        ...state.portfolio,
        positions: newPositions,
        cash: state.portfolio.cash + recoveryValue
      }
    };
  }

  return {
    ...updatedState,
    assets: {
      ...updatedState.assets,
      [asset.category]: updatedState.assets[asset.category].filter(a => a.symbol !== asset.symbol)
    },
    pendingNotification: {
      type: 'error',
      title: 'Company Failure',
      message: `${asset.name} (${asset.symbol}) has failed. Shareholders recovered 10% of their investment`
    }
  };
};

export const addNewAsset = (state, category, newAsset) => {
  return {
    ...state,
    assets: {
      ...state.assets,
      [category]: [...state.assets[category], newAsset]
    },
    news: [
      {
        type: 'ipo',
        title: `New ${category.slice(0, -1).toUpperCase()} Listed: ${newAsset.name}`,
        content: `${newAsset.symbol} has been listed at $${newAsset.price}.`,
        timestamp: Date.now()
      },
      ...state.news
    ]
  };
};

export const generateMarketNews = (state, count = 5) => {
  const newsItems = [];
  
  // Remove random news generation and only keep event-based news
  // The news will now only come from:
  // 1. IPOs (already handled in handleIPO)
  // 2. Buybacks (already handled in handleBuyback)
  // 3. Company Failures (already handled in handleFailure)
  
  // Sort by timestamp and return most recent
  return newsItems
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, count);
};

export const generateInitialMarketData = () => {
  return {
    stocks: [
      { symbol: 'TECH', name: 'TechCorp', price: 100, priceChange: 0, marketCap: 1000000 },
      { symbol: 'FNCE', name: 'FinanceGroup', price: 150, priceChange: 0, marketCap: 1500000 },
      { symbol: 'ENRG', name: 'EnergyInc', price: 75, priceChange: 0, marketCap: 750000 },
    ],
    etfs: [
      { symbol: 'INDX', name: 'Market Index', price: 200, priceChange: 0, marketCap: 2000000 },
      { symbol: 'TECH', name: 'Tech Sector', price: 180, priceChange: 0, marketCap: 1800000 },
    ],
    commodities: [
      { symbol: 'GOLD', name: 'Gold', price: 1800, priceChange: 0, marketCap: 18000000 },
      { symbol: 'OIL', name: 'Crude Oil', price: 75, priceChange: 0, marketCap: 750000 },
    ],
    crypto: [
      { symbol: 'BTC', name: 'Bitcoin', price: 45000, priceChange: 0, marketCap: 450000000 },
      { symbol: 'ETH', name: 'Ethereum', price: 3000, priceChange: 0, marketCap: 30000000 },
    ]
  };
};

export const handleIPO = (state) => {
  // Randomly select category with weights
  const categories = ['stocks', 'etfs', 'commodities', 'crypto'];
  const weights = [0.5, 0.25, 0.15, 0.1];
  
  let category;
  const roll = Math.random();
  let cumulative = 0;
  for (let i = 0; i < weights.length; i++) {
    cumulative += weights[i];
    if (roll < cumulative) {
      category = categories[i];
      break;
    }
  }

  const newAsset = MarketManager.getRandomNewAsset(category, state.assets);
  if (!newAsset) return state;

  // Add notification data to state
  return {
    ...addNewAsset(state, category, newAsset),
    pendingNotification: {
      type: 'info',
      title: 'New IPO!',
      message: `${newAsset.name} (${newAsset.symbol}) has entered the market at $${newAsset.price.toFixed(2)}`
    }
  };
};

export const handleAcquisition = (state, asset) => {
  const acquiredAsset = MarketManager.handleAssetAcquisition(asset);
  const newAssets = { ...state.assets };
  newAssets[asset.category] = newAssets[asset.category].filter(a => a.symbol !== asset.symbol);

  return {
    ...state,
    assets: newAssets,
    news: [
      {
        type: 'acquisition',
        title: `${asset.name} Acquired`,
        content: `${asset.symbol} has been acquired at a premium.`,
        timestamp: Date.now()
      },
      ...state.news
    ]
  };
};