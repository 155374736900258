import { Grid } from '@mui/material';
import WorkButton from './WorkButton';
import TrainingButton from './TrainingButton';
import StaffManager from './StaffManager';
import UpgradesPanel from './UpgradesPanel';
import StatsPanel from './StatsPanel';

const GamePanel = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatsPanel />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <WorkButton />
          </Grid>
          <Grid item xs={12} md={6}>
            <TrainingButton />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StaffManager />
          </Grid>
          <Grid item xs={12} md={6}>
            <UpgradesPanel />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GamePanel;