import { Card, Typography, Stack, Box, Chip } from '@mui/material';
import { useMarket } from '../../../contexts/MarketContext';
import { formatDate } from '../../../utils/formatters';

const NewsFeed = () => {
  const { state } = useMarket();
  
  // Early return if news isn't loaded
  if (!state?.news || !Array.isArray(state.news)) {
    return (
      <Card variant="outlined" sx={{ p: 2 }}>
        <Typography variant="h6">Market News</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>No news available</Typography>
      </Card>
    );
  }

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Typography variant="h6">Market News</Typography>
      <Stack spacing={2} sx={{ mt: 2 }}>
        {state.news.map((item, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              borderRadius: 1,
              bgcolor: 'background.paper',
              border: 1,
              borderColor: 'divider'
            }}
          >
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {item.symbol}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 0.5 }}>
                    {item.title}
                  </Typography>
                </Box>
                <Chip
                  size="small"
                  label={item.impact}
                  color={item.impact === 'positive' ? 'success' : 
                         item.impact === 'negative' ? 'error' : 
                         'default'}
                />
              </Box>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatDate(item.timestamp)}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Card>
  );
};

export default NewsFeed;
