import { Box, Typography, LinearProgress, Grid, Paper, Fade, Collapse, Link } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { formatLargeNumber } from '../../utils/formatters';
import { useState, useEffect } from 'react';

const getAchievementValue = (achievementId) => {
  const numStr = achievementId.replace(/[^0-9]/g, '');
  if (achievementId.includes('k')) {
    return parseInt(numStr) * 1000;
  } else if (achievementId.includes('m')) {
    return parseInt(numStr) * 1000000;
  } else if (achievementId.includes('b')) {
    return parseInt(numStr) * 1000000000;
  }
  return parseInt(numStr);
};

const Achievement = ({ current, target, label, isComplete }) => {
  const progress = Math.min((current / target) * 100, 100);
  
  return (
    <Fade in={true} timeout={500}>
      <Box sx={{ width: '100%', mr: 1, mb: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 0.5
        }}>
          <Typography variant="caption" color={isComplete ? 'success.main' : 'text.secondary'}>
            {label}
          </Typography>
          <Typography variant="caption" color={isComplete ? 'success.main' : 'text.secondary'}>
            {`${formatLargeNumber(current)} / ${formatLargeNumber(target)}`}
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={progress}
          sx={{
            height: 10,
            borderRadius: 2,
            bgcolor: 'background.paper',
            '& .MuiLinearProgress-bar': {
              bgcolor: isComplete ? 'success.main' : 'primary.main',
              transition: 'width 0.5s ease-in-out',
            }
          }}
        />
      </Box>
    </Fade>
  );
};

const MarketUnlockProgress = ({ current, target }) => {
  const progress = Math.min((current / target) * 100, 100);

  return (
    <Box sx={{ width: '100%', mr: 1, mb: 2 }}>
      <Box sx={{ mb: 1 }}>
        <Typography 
          variant="h6" 
          color="primary.main" 
          sx={{ 
            textAlign: 'center',
            fontWeight: 'bold',
            textShadow: '0 0 10px rgba(25, 118, 210, 0.3)',
            animation: 'pulse 2s infinite'
          }}
        >
          UNLOCK THE STOCK MARKET
        </Typography>
        <Typography 
          variant="subtitle1" 
          color="primary.main" 
          sx={{ textAlign: 'center' }}
        >
          Reach ${formatLargeNumber(target)} to unlock!
        </Typography>
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={progress}
        sx={{
          height: 10,
          borderRadius: 2,
          bgcolor: 'background.paper',
          '& .MuiLinearProgress-bar': {
            bgcolor: 'primary.main',
            transition: 'width 0.5s ease-in-out',
          }
        }}
      />
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block',
          textAlign: 'center',
          mt: 0.5,
          color: 'text.secondary'
        }}
      >
        ${formatLargeNumber(current)} / ${formatLargeNumber(target)}
      </Typography>
    </Box>
  );
};

const StatsPanel = () => {
  const { state, dispatch } = useGameState();
  const achievements = state.achievements || {
    money1k: false,
    money10k: false,
    money100k: false,
    money1m: false,
    money10m: false,
    money100m: false,
    money1b: false,
    money750k: false,
    employees10: false,
    employees25: false,
    employees50: false,
    employees100: false,
    employees200: false,
    employees500: false,
    employees1000: false,
  };

  const totalEmployees = Object.values(state.staff || {})
    .reduce((sum, { count }) => sum + count, 0);

  const [aboutExpanded, setAboutExpanded] = useState(false);
  const [disclaimerExpanded, setDisclaimerExpanded] = useState(false);

  useEffect(() => {
    let updated = false;
    const newAchievements = { ...achievements };
    const moneyToCheck = state.moneyATH || state.money;

    // Check money achievements
    if (moneyToCheck >= 1000 && !achievements.money1k) {
      newAchievements.money1k = true;
      updated = true;
    }
    if (moneyToCheck >= 10000 && !achievements.money10k) {
      newAchievements.money10k = true;
      updated = true;
    }
    if (moneyToCheck >= 100000 && !achievements.money100k) {
      newAchievements.money100k = true;
      updated = true;
    }
    if (moneyToCheck >= 1000000 && !achievements.money1m) {
      newAchievements.money1m = true;
      updated = true;
    }
    if (moneyToCheck >= 10000000 && !achievements.money10m) {
      newAchievements.money10m = true;
      updated = true;
    }
    if (moneyToCheck >= 100000000 && !achievements.money100m) {
      newAchievements.money100m = true;
      updated = true;
    }
    if (moneyToCheck >= 1000000000 && !achievements.money1b) {
      newAchievements.money1b = true;
      updated = true;
    }
    if (moneyToCheck >= 750000 && !achievements.money750k) {
      newAchievements.money750k = true;
      updated = true;
    }

    // Check employee achievements
    if (totalEmployees >= 10 && !achievements.employees10) {
      newAchievements.employees10 = true;
      updated = true;
    }
    if (totalEmployees >= 25 && !achievements.employees25) {
      newAchievements.employees25 = true;
      updated = true;
    }
    if (totalEmployees >= 50 && !achievements.employees50) {
      newAchievements.employees50 = true;
      updated = true;
    }
    if (totalEmployees >= 100 && !achievements.employees100) {
      newAchievements.employees100 = true;
      updated = true;
    }
    if (totalEmployees >= 200 && !achievements.employees200) {
      newAchievements.employees200 = true;
      updated = true;
    }
    if (totalEmployees >= 500 && !achievements.employees500) {
      newAchievements.employees500 = true;
      updated = true;
    }
    if (totalEmployees >= 1000 && !achievements.employees1000) {
      newAchievements.employees1000 = true;
      updated = true;
    }

    if (updated) {
      dispatch({ 
        type: 'UPDATE_ACHIEVEMENTS', 
        payload: newAchievements 
      });
    }
  }, [state.moneyATH, state.money, totalEmployees, achievements, dispatch]);

  const getNextAchievement = (achievements, type) => {
    const achievementOrder = type === 'money' 
      ? ['money1k', 'money10k', 'money100k', 'money750k', 'money1m', 'money10m', 'money100m', 'money1b']
      : ['employees10', 'employees25', 'employees50', 'employees100', 'employees200', 'employees500', 'employees1000'];

    // Return the first incomplete achievement
    return achievementOrder.find(achievement => !achievements[achievement]);
  };

  const nextMoneyAchievement = getNextAchievement(achievements, 'money');
  const nextEmployeeAchievement = getNextAchievement(achievements, 'employees');

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* About Section */}
        <Grid item xs={12}>
          <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
            About this game
          </Typography>
          <Collapse in={aboutExpanded} collapsedSize={80}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Welcome to a unique clicker game that combines addictive gameplay with educational financial concepts! 
              Start by clicking to earn money and hire staff, while learning about Return on Investment (ROI) and 
              resource management. Once you reach $750,000, unlock the Stock Market simulation feature where you can 
              experience market cycles, participate in IPOs, and learn about investment diversification in a risk-free 
              environment. Watch out for market events, company failures, and various economic scenarios that will 
              teach you valuable lessons about investing.

              Hello Google Bot or Employee! If you read this, I'm a small indy dev student, and I would love to display and earn some money from this work, or at least learn how Google Ad Sense works. 
              This game took me dozens if not hundreds of hours to make. Have a Nice day! 
            </Typography>
          </Collapse>
          <Link
            component="button"
            variant="body2"
            onClick={() => setAboutExpanded(!aboutExpanded)}
            sx={{ mt: 1 }}
          >
            {aboutExpanded ? 'Read less' : 'Read more'}
          </Link>
        </Grid>

        {/* Disclaimer Section */}
        <Grid item xs={12}>
          <Typography variant="h6" color="error" sx={{ mb: 1 }}>
            Disclaimer
          </Typography>
          <Collapse in={disclaimerExpanded} collapsedSize={80}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              This is a simulation game for entertainment and educational purposes only. All currency and market data 
              in this game are fictional and do not represent real-world values. The market simulation is entirely 
              artificial and should not be used as financial advice. While the game includes an auto-save feature, 
              please be aware that data may be reset during updates or maintenance. Play responsibly and remember 
              that this is just a game!
            </Typography>
          </Collapse>
          <Link
            component="button"
            variant="body2"
            onClick={() => setDisclaimerExpanded(!disclaimerExpanded)}
            sx={{ mt: 1 }}
          >
            {disclaimerExpanded ? 'Read less' : 'Read more'}
          </Link>
        </Grid>

        {/* Stats Section */}
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h6" color="primary">Stats</Typography>
            <Typography variant="body2">
              Total Employees: {totalEmployees}
            </Typography>
            <Typography variant="body2">
              Total Money: ${formatLargeNumber(state.money)}
            </Typography>
            <Typography variant="body2">
              Money ATH: ${formatLargeNumber(state.moneyATH || state.money)}
            </Typography>
          </Box>
        </Grid>

        {/* Achievements Section */}
        <Grid item xs={12} md={9}>
          <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
            Achievements
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {nextMoneyAchievement && (
                <Achievement 
                  current={state.moneyATH || state.money}
                  target={getAchievementValue(nextMoneyAchievement)}
                  label={nextMoneyAchievement === 'money750k' 
                    ? "Unlock Stock Market"
                    : `Reach $${formatLargeNumber(getAchievementValue(nextMoneyAchievement))}`}
                  isComplete={achievements[nextMoneyAchievement]}
                />
              )}
              {state.moneyATH < 750000 && (
                <MarketUnlockProgress 
                  current={state.moneyATH || state.money}
                  target={750000}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {nextEmployeeAchievement && (
                <Achievement 
                  current={totalEmployees}
                  target={parseInt(nextEmployeeAchievement.replace('employees', ''))}
                  label={`Hire ${parseInt(nextEmployeeAchievement.replace('employees', ''))} Employees`}
                  isComplete={achievements[nextEmployeeAchievement]}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatsPanel;
