import { GAME_CONSTANTS } from '../data/gameConstants';

export const calculateCompoundInterest = (principal, rate, time) => {
  return Number((principal * Math.pow(1 + rate, time)).toFixed(2));
};

export const calculateMovingAverage = (data, period) => {
  const result = [];
  for (let i = period - 1; i < data.length; i++) {
    const slice = data.slice(i - period + 1, i + 1);
    const average = Number((slice.reduce((sum, value) => sum + value, 0) / period).toFixed(2));
    result.push(average);
  }
  return result;
};

export const calculateVolatility = (prices, period = 14) => {
  if (prices.length < 2) return 0;
  
  const returns = [];
  for (let i = 1; i < prices.length; i++) {
    returns.push(Number(((prices[i] - prices[i-1]) / prices[i-1]).toFixed(2)));
  }
  
  const mean = Number((returns.reduce((sum, value) => sum + value, 0) / returns.length).toFixed(2));
  const squaredDiffs = returns.map(value => Number((Math.pow(value - mean, 2)).toFixed(2)));
  const variance = Number((squaredDiffs.reduce((sum, value) => sum + value, 0) / squaredDiffs.length).toFixed(2));
  
  return Number((Math.sqrt(variance * 252)).toFixed(2));
};

export const calculateRiskAdjustedReturn = (returns, riskFreeRate = 0.02) => {
  const volatility = calculateVolatility(returns);
  if (volatility === 0) return 0;
  
  const meanReturn = returns.reduce((sum, value) => sum + value, 0) / returns.length;
  return (meanReturn - riskFreeRate) / volatility;
};

export const calculateTotalIncome = (staffState) => {
  return Number(Object.entries(staffState).reduce((total, [type, data]) => {
    const baseIncome = GAME_CONSTANTS.STAFF[type].BASE_INCOME;
    const multiplier = Number((Math.pow(GAME_CONSTANTS.STAFF[type].INCOME_MULTIPLIER, data.count)).toFixed(2));
    return total + Number((baseIncome * data.count * multiplier).toFixed(2));
  }, 0).toFixed(2));
};

export const calculateNextUpgradeCost = (basePrice, currentLevel, multiplier) => {
  return Math.floor(basePrice * Math.pow(multiplier, currentLevel));
};

// Perlin noise implementation for market cycles
export const generatePerlinNoise = (x) => {
  // Constants for the noise generation
  const PERMUTATION = new Array(256).fill(0).map((_, i) => i);
  for (let i = 255; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [PERMUTATION[i], PERMUTATION[j]] = [PERMUTATION[j], PERMUTATION[i]];
  }
  const P = [...PERMUTATION, ...PERMUTATION];

  function fade(t) {
    return t * t * t * (t * (t * 6 - 15) + 10);
  }

  function lerp(t, a, b) {
    return a + t * (b - a);
  }

  function grad(hash, x) {
    const h = hash & 15;
    const grad = 1 + (h & 7);
    return (h & 8 ? -grad : grad) * x;
  }

  const X = Math.floor(x) & 255;
  x -= Math.floor(x);
  const u = fade(x);

  const a = P[X];
  const b = P[X + 1];

  // Return a value between 0 and 1
  return (0.5 + 0.5 * lerp(
    u,
    grad(P[a], x),
    grad(P[b], x - 1)
  ));
};

// Market simulation helper functions
export const calculateMarketTrend = (currentPrice, volatility, marketCycle, timeScale = 1) => {
  const cycleBias = Number(((marketCycle - 0.5) * 2).toFixed(2)); // Convert 0-1 to -1 to 1
  const randomFactor = Number(((Math.random() * 2 - 1) * volatility).toFixed(2));
  const trendFactor = Number(((cycleBias * volatility + randomFactor) * timeScale).toFixed(2));
  return Number((currentPrice * (1 + trendFactor)).toFixed(2));
};

export const calculateMarketSentiment = (prices, period = 14) => {
  if (prices.length < period) return 0.5;
  
  const returns = prices.slice(-period).map((price, i, arr) => 
    i === 0 ? 0 : Number(((price - arr[i-1]) / arr[i-1]).toFixed(2))
  );
  
  const positiveReturns = returns.filter(r => r > 0).length;
  return Number((positiveReturns / period).toFixed(2));
};