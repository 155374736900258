import { Box, Typography, LinearProgress } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { alpha } from '@mui/material/styles';
import { useMarket } from '../../../contexts/MarketContext';

const CycleIndicator = () => {
  const { state: marketState } = useMarket();
  const phase = marketState.marketCycle.phase;
  const strength = marketState.marketCycle.strength;
  const isBull = phase.toLowerCase() === 'bull';
  const strengthPercentage = Math.abs((strength - 0.5) * 200);

  return (
    <Box sx={{
      p: 2,
      borderRadius: 2,
      bgcolor: (theme) => alpha(theme.palette[isBull ? 'success' : 'error'].main, 0.15),
      border: 1,
      borderColor: (theme) => alpha(theme.palette[isBull ? 'success' : 'error'].main, 0.3),
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography level="body-sm" color="error">BEAR MARKET</Typography>
        <Typography level="body-sm" color="success">BULL MARKET</Typography>
      </Box>
      <Box sx={{ position: 'relative', mt: 2 }}>
        <LinearProgress
          variant="determinate"
          value={strengthPercentage}
          sx={{
            position: 'absolute',
            width: '50%',
            height: 8,
            borderRadius: 4,
            left: strength >= 0.5 ? '50%' : 'auto',
            right: strength < 0.5 ? '50%' : 'auto',
            transform: strength < 0.5 ? 'scaleX(-1)' : 'none',
            bgcolor: (theme) => alpha(theme.palette[isBull ? 'success' : 'error'].main, 0.1),
            '& .MuiLinearProgress-bar': {
              bgcolor: (theme) => isBull ? theme.palette.success.main : theme.palette.error.main,
            }
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: 0,
            bottom: 0,
            width: 2,
            bgcolor: 'divider',
            transform: 'translateX(-50%)',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            height: 8,
            bgcolor: 'background.paper',
            borderRadius: 4,
            border: 1,
            borderColor: 'divider',
          }}
        />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        mt: 1,
        gap: 1,
        alignItems: 'center'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isBull ? <TrendingUpIcon color="success" /> : <TrendingDownIcon color="error" />}
          <Typography 
            variant="h5"
            color={isBull ? 'success.main' : 'error.main'}
            sx={{ fontWeight: 600 }}
          >
            {phase.toUpperCase()}
          </Typography>
        </Box>
        <Typography variant="body2" color={isBull ? 'success.main' : 'error.main'} sx={{ opacity: 0.8 }}>
          {strengthPercentage.toFixed(2)}% strength
        </Typography>
      </Box>
    </Box>
  );
};

export default CycleIndicator;
