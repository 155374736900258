import { Card, Typography, Box, Stack } from '@mui/material';
import { useMarket } from '../../../contexts/MarketContext';
import { formatLargeNumber } from '../../../utils/formatters';

const AssetBreakdown = () => {
  const { state } = useMarket();

  // Early return if portfolio isn't loaded
  if (!state?.portfolio?.holdings) {
    return null;
  }

  const { holdings } = state.portfolio;
  const categories = {
    stocks: 0,
    etfs: 0,
    commodities: 0,
    crypto: 0
  };

  // Calculate total value for each category
  Object.entries(holdings).forEach(([symbol, quantity]) => {
    for (const [category, assets] of Object.entries(state.assets)) {
      const asset = assets.find(a => a.symbol === symbol);
      if (asset) {
        categories[category] += asset.price * quantity;
        break;
      }
    }
  });

  const totalValue = Object.values(categories).reduce((sum, value) => sum + value, 0);

  return (
    <Card variant="outlined">
      <Typography level="h4">Portfolio Breakdown</Typography>
      <Stack spacing={1} mt={2}>
        {Object.entries(categories).map(([category, value]) => (
          <Box key={category} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>{category.toUpperCase()}</Typography>
            <Typography>
              {totalValue > 0 ? `${((value / totalValue) * 100).toFixed(1)}%` : '0%'}
              {' '}(${formatLargeNumber(value)})
            </Typography>
          </Box>
        ))}
      </Stack>
    </Card>
  );
};

export default AssetBreakdown;
