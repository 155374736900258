import { GAME_CONSTANTS } from '../data/gameConstants';

export const calculateStaffCost = (staffType, currentCount) => {
  const staffConfig = GAME_CONSTANTS.STAFF[staffType.toUpperCase()];
  return Math.floor(staffConfig.BASE_COST * Math.pow(staffConfig.COST_MULTIPLIER, currentCount));
};

export const getAvailableStaffTypes = (state) => {
  return Object.entries(GAME_CONSTANTS.STAFF).map(([key, config]) => ({
    id: key.toLowerCase(),
    name: config.name,
    baseCost: config.BASE_COST,
    baseIncome: config.BASE_INCOME,
    requirement: config.requirement
  })).filter(staffType => {
    // If no requirement, always available (like intern)
    if (!staffType.requirement) return true;
    
    // Check staff requirements
    if (staffType.requirement.staff) {
      return Object.entries(staffType.requirement.staff).every(([type, count]) => {
        // Safely access staff count, default to 0 if undefined
        const currentCount = state.staff?.[type]?.count || 0;
        return currentCount >= count;
      });
    }
    
    return true;
  });
};

export const calculateStaffIncome = (staffType, count, multiplier = 1) => {
  const staffConfig = GAME_CONSTANTS.STAFF[staffType.toUpperCase()];
  if (!count || count <= 0) return 0;
  
  // Calculate base income with multiplier and income multiplier
  const baseIncome = staffConfig.BASE_INCOME * count;
  const multiplierBonus = multiplier || 1;
  const incomeMultiplier = Math.pow(staffConfig.INCOME_MULTIPLIER, count - 1);
  
  return baseIncome * multiplierBonus * incomeMultiplier;
};

export const handleStaffHire = (state, staffId) => {  
  const currentCount = state.staff[staffId]?.count || 0;
  const cost = calculateStaffCost(staffId, currentCount);
  
  if (state.money >= cost) {
    return {
      ...state,
      money: state.money - cost,
      staff: {
        ...state.staff,
        [staffId]: {
          ...state.staff[staffId],
          count: currentCount + 1,
          multiplier: state.staff[staffId]?.multiplier || 1
        }
      }
    };
  }
  
  return state;
};

export const updateStaffIncome = (state) => {
  if (!state.staff) return 0;
  
  // Calculate total income from all staff
  const totalIncome = Object.entries(state.staff).reduce((total, [staffType, staffData]) => {
    if (!staffData || !staffData.count) return total;
    
    const income = calculateStaffIncome(
      staffType,
      staffData.count,
      staffData.multiplier || 1
    );
    
    return total + income;
  }, 0);

  // Return with 2 decimal places precision
  return Number(totalIncome.toFixed(2));
};

export const getStaffEfficiency = (staffId, state) => {
  const staffConfig = GAME_CONSTANTS.STAFF[staffId.toUpperCase()];
  const baseEfficiency = 1;
  // Apply any upgrades or multipliers here
  return baseEfficiency;
};