import { 
  Table, 
  Paper, 
  Typography, 
  Button, 
  Box,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import { useState } from 'react';
import { useMarket } from '../../../contexts/MarketContext';
import { formatLargeNumber } from '../../../utils/formatters';
import TradeModal from './TradeModal';

const AssetTable = ({ category }) => {
  const { state } = useMarket();
  const [selectedAsset, setSelectedAsset] = useState(null);
  
  if (!state?.assets?.[category]) return null;

  const assets = state.assets[category];

  return (
    <TableContainer 
      component={Paper} 
      variant="outlined"
      sx={{ 
        borderRadius: 1,
        bgcolor: 'background.paper',
        '& .MuiTableCell-root': {  // Add responsive padding
          px: { xs: 1, sm: 2 },    // Less padding on mobile
          py: { xs: 2, sm: 1.5 },  // More vertical space on mobile
        }
      }}
    >
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Symbol</TableCell>
            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Name</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Change</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset) => {
            const priceChange = ((asset.price - asset.previousPrice) / asset.previousPrice) * 100;
            const changeColor = priceChange >= 0 ? 'success.main' : 'error.main';
            
            return (
              <TableRow 
                key={asset.symbol}
                sx={{
                  '&:hover': {
                    bgcolor: 'action.hover',
                  }
                }}
              >
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Typography 
                      variant="body2" 
                      fontWeight="500"
                      sx={{ fontSize: { xs: '0.9rem', sm: '0.875rem' } }}
                    >
                      {asset.symbol}
                    </Typography>
                    <Typography 
                      variant="body2"
                      color="text.secondary"
                      sx={{ 
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                        display: { xs: 'block', sm: 'none' }
                      }}
                    >
                      {asset.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  <Typography 
                    variant="body2"
                    sx={{ fontSize: { xs: '0.9rem', sm: '0.875rem' } }}
                  >
                    {asset.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography 
                    variant="body2"
                    sx={{ fontSize: { xs: '0.9rem', sm: '0.875rem' } }}
                  >
                    ${Number(asset.price).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography 
                    variant="body2" 
                    color={changeColor}
                    sx={{ fontSize: { xs: '0.9rem', sm: '0.875rem' } }}
                  >
                    {priceChange >= 0 ? '+' : ''}{priceChange.toFixed(2)}%
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Button 
                    variant="contained"
                    size="small"
                    sx={{
                      px: { xs: 2, sm: 1.5 },
                      py: { xs: 1, sm: 0.5 }
                    }}
                    onClick={() => setSelectedAsset(asset)}
                  >
                    Trade
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {selectedAsset && (
        <TradeModal
          asset={selectedAsset}
          onClose={() => setSelectedAsset(null)}
        />
      )}
    </TableContainer>
  );
};

export default AssetTable;
