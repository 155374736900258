import { GAME_CONSTANTS } from '../data/gameConstants';
import { updateStaffIncome } from './staffService';

export const getAvailableUpgrades = (state) => {
  const purchasedUpgrades = Object.keys(state.upgrades || {});
  
  return GAME_CONSTANTS.UPGRADES.GLOBAL.filter(upgrade => {
    // Skip if already purchased
    if (purchasedUpgrades.includes(upgrade.id)) return false;
    
    // Check staff requirements
    if (upgrade.requirement?.staff) {
      for (const [staffType, count] of Object.entries(upgrade.requirement.staff)) {
        if (!state.staff[staffType] || state.staff[staffType].count < count) {
          return false;
        }
      }
    }
    
    return true;
  });
};

// Update debug logging
export const debugAvailableUpgrades = (state) => {
  
  GAME_CONSTANTS.UPGRADES.GLOBAL.forEach(upgrade => {
    const staffRequirementsMet = upgrade.requirement?.staff
      ? Object.entries(upgrade.requirement.staff).every(([type, count]) => 
          (state?.staff?.[type]?.count || 0) >= count)
      : true;
  });
};

export const applyUpgradeEffects = (state, upgrade) => {
  const newState = {
    ...state,
    money: state.money - upgrade.cost,
    upgrades: [...(state.upgrades || []), upgrade.id],
    staff: { ...state.staff }
  };

  if (upgrade.affects === 'all') {
    // Update all staff multipliers
    Object.keys(newState.staff).forEach(staffType => {
      if (newState.staff[staffType]) {
        newState.staff[staffType] = {
          ...newState.staff[staffType],
          multiplier: (newState.staff[staffType].multiplier || 1) * upgrade.multiplier
        };
      }
    });
  } else if (Array.isArray(upgrade.affects)) {
    // Update specific staff multipliers
    upgrade.affects.forEach(staffType => {
      if (newState.staff[staffType]) {
        newState.staff[staffType] = {
          ...newState.staff[staffType],
          multiplier: (newState.staff[staffType].multiplier || 1) * upgrade.multiplier
        };
      }
    });
  }

  // Recalculate income after multiplier changes
  newState.incomePerSecond = updateStaffIncome(newState);
  
  return newState;
};