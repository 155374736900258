import { useEffect } from 'react';
import { Card, Stack, Typography, Button, Tooltip, Box } from '@mui/material';
import { useGameState } from '../../contexts/GameStateContext';
import { getAvailableUpgrades, debugAvailableUpgrades } from '../../services/upgradeService';
import { formatLargeNumber } from '../../utils/formatters';
import UpgradeIcon from '@mui/icons-material/Upgrade';

const UpgradesPanel = () => {
  const { state, dispatch } = useGameState();
  const availableUpgrades = getAvailableUpgrades(state);

  useEffect(() => {
    debugAvailableUpgrades(state);
  }, [state.money, state.upgrades]);

  const handlePurchase = (upgrade) => {
    if (state.money >= upgrade.cost) {
      console.log('Purchasing upgrade:', upgrade);
      dispatch({
        type: 'PURCHASE_UPGRADE',
        payload: upgrade
      });
    }
  };

  if (availableUpgrades.length === 0) return null;

  return (
    <Card 
      variant="outlined" 
      sx={{ 
        p: 3,
        mt: 2,
        borderRadius: 2,
        bgcolor: 'background.paper',
        background: (theme) => `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.secondary.light}15 100%)`,
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1, 
        mb: 3 
      }}>
        <UpgradeIcon sx={{ color: 'secondary.main', fontSize: 28 }} />
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Available Upgrades
        </Typography>
      </Box>
      <Stack spacing={2}>
        {availableUpgrades.map(upgrade => (
          <Tooltip 
            key={upgrade.id} 
            title={upgrade.description}
            arrow
            placement="top"
          >
            <Button
              variant="contained"
              color={state.money >= upgrade.cost ? "primary" : "secondary"}
              onClick={() => handlePurchase(upgrade)}
              disabled={state.money < upgrade.cost}
              sx={{ 
                width: '100%',
                minHeight: '80px',
                p: 3,
                borderRadius: 2,
                background: (theme) => state.money >= upgrade.cost 
                  ? `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                  : undefined,
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: state.money >= upgrade.cost ? 'translateY(-2px)' : 'none',
                  boxShadow: (theme) => state.money >= upgrade.cost 
                    ? `0 8px 24px ${theme.palette.primary.main}40`
                    : undefined,
                },
                '&:active': {
                  transform: 'translateY(1px)',
                },
              }}
            >
              <Stack 
                spacing={1} 
                alignItems="flex-start" 
                width="100%"
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 600,
                    color: 'white'
                  }}
                >
                  {upgrade.name} (${formatLargeNumber(upgrade.cost)})
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: 'white',
                    opacity: 0.9
                  }}
                >
                  {upgrade.description}
                </Typography>
              </Stack>
            </Button>
          </Tooltip>
        ))}
      </Stack>
    </Card>
  );
};

export default UpgradesPanel;